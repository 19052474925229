import { createReducer } from '@reduxjs/toolkit';
import * as types from './type';

const reducerSchema = (types) => {
  const initialState = {
    series: {},
  };

  return createReducer(initialState, {
    [types.ACTION_RESET]: () => ({
      ...initialState,
    }),
    [types.ACTION_ERROR]: (state, action) => {
      const seriesClone = { ...state.series };
      seriesClone[action.payload.params.query] = {
        error: true,
        isLoading: false,
        params: action.payload.params,
        data: action.payload.data,
      };
      return {
        ...state,
        series: seriesClone,
      };
    },
    [types.ACTION_SUCCESS]: (state, action) => {
      const seriesClone = { ...state.series };
      seriesClone[action.payload.params.query] = {
        error: false,
        isLoading: false,
        params: action.payload.params,
        data: action.payload.data,
      };
      return {
        ...state,
        series: seriesClone,
      };
    },
    [types.ACTION_REQUEST]: (state, action) => {
      const seriesClone = { ...state.series };
      seriesClone[action.payload.params.query] = {
        error: false,
        isLoading: true,
        params: action.payload.params,
      };
      return {
        ...state,
        series: seriesClone,
      };
    },
  });
};

const reducer = reducerSchema(types);

export default reducer;
