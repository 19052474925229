export default {
  lscsPath: 'https://api-cms-sit.hktdc.com/ls/v2',
  projectNamePath: '%2F%2Fcmsapp%2F',
  apiContext: '',
  subDirectoryPath: '',
  routePathTo: `/${process.env.REACT_APP_TSPROJECTNAME}`,
  locationServiceEndPoint: 'https://api-general-uat.hktdc.com/location/v1/location-service',
  footer_js_env: '-uat',
  autoCompletePath: 'https://api-search-sit.hktdc.com/autocomplete/v1/autocomplete-search',
  eventPath: 'https://event-uat.hktdc.com',
  researchDomain: 'https://research-sit.hktdc.com',
  sbeProgramePath: 'https://api-cms-sit.hktdc.com/sbe/v1/seminar/programme',
  sbeSpeakerPath: 'https://api-cms-sit.hktdc.com/sbe/v1/seminar/speakers',
  searchCat: 'dac',
  academySearchPath: 'http://localhost:3001/searchResult',
};
