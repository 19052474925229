import { createAction } from '@reduxjs/toolkit';
import * as types from './type';
import axiosAdapter from '../../../utils/Adapters';
import { CMSAPI } from '../../../utils/CmsAPI';
import config from '../../../config';

const requestFetch = createAction(types.ACTION_REQUEST);
const receiveSuccess = createAction(types.ACTION_SUCCESS);
const receiveError = createAction(types.ACTION_ERROR);

const massageData = (response) => {
  const responseData = response.data;
  return responseData.result.assets[0].content.content;
};

const messageError = (error) => {
  const message =
    ((((error || {}).response || {}).data || {}).error || {}).message || error.message;
  return message;
};

const fetchData = () => {
  const url = CMSAPI(
    config.lscsPath,
    'academyConfig',
    config.pageType.academyConfig,
    config.cmsProjectName,
    false,
  );
  return axiosAdapter({
    url,
  });
};

const getAcademyConfig = () => {
  return (dispatch) => {
    dispatch(requestFetch());
    return fetchData()
      .then((response) => massageData(response))
      .then((data) => {
        dispatch(receiveSuccess(data));
      })
      .catch((error) => {
        const message = messageError(error);
        dispatch(receiveError(message));
      });
  };
};

export { getAcademyConfig };
