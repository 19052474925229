import { createReducer } from '@reduxjs/toolkit';
import * as types from './type';

const reducerSchema = (types) => {
  const initialState = {
    isLoading: false,
    sortingMode: 'relevancy',
    data: [],
    page: 1,
    total: 0,
    hasMore: true,
    error: false,
    isSearchOverlayOpen: false,
  };

  return createReducer(initialState, {
    [types.ACTION_RESET]: (state) => ({
      ...state,
      isLoading: true,
      data: [],
      page: 1,
      total: 0,
      hasMore: true,
      error: false,
    }),
    [types.ACTION_ERROR]: (state) => ({
      ...state,
      isLoading: false,
      hasMore: false,
      error: true,
    }),
    [types.ACTION_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload.result,
      page: action.payload.page,
      total: action.payload.total,
      hasMore: true,
      error: false,
    }),
    [types.ACTION_SET_OVERLAY_DISPLAY]: (state, action) => ({
      ...state,
      isSearchOverlayOpen: action.payload,
    }),
    [types.ACTION_SET_END_PAGE]: (state) => ({
      ...state,
      hasMore: false,
    }),
    [types.ACTION_SET_SORTING]: (state, action) => ({
      ...state,
      sortingMode: action.payload,
    }),
  });
};

const reducer = reducerSchema(types);

export default reducer;
