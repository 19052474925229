import { createReducer } from '@reduxjs/toolkit';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import truncate from 'truncate-html';
import moment from 'moment-timezone';
import { isObject, isArray, isEmpty } from 'lodash';
import config from '../config';

const { sprintf } = require('sprintf-js');

const isSSOLoggedIn = async () => {
  if (window.ssoClient) {
    return (await window.ssoClient.getAccessToken()) || false;
  }
  return false;
};

const isExternalURL = (str) => {
  const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if (!regex.test(str)) {
    return false;
  }
  return true;
};

const constructPath = (routeTo, lang, pageType, pageName) => {
  return `${routeTo}/${lang}/${pageType}/${pageName}`;
};

const newEle = (id, src, target, tag = 'script', type = '', async = true) => {
  let js = document.getElementById(id);
  if (js) {
    js.parentNode.removeChild(js); // don't use .remove() when IE doesn't work
  }
  if (tag === 'stylesheet') {
    const jsPreloadId = `${id}PreLoad`;
    let jsPreload = document.getElementById(jsPreloadId);
    if (jsPreload) {
      jsPreload.parentNode.removeChild(jsPreload); // don't use .remove() when IE doesn't work
    }

    jsPreload = document.createElement('link');
    jsPreload.setAttribute('href', src);
    jsPreload.setAttribute('rel', 'preload');
    jsPreload.setAttribute('as', 'style');
    jsPreload.setAttribute('id', jsPreloadId);
    jsPreload.async = async;
    if (type !== '') jsPreload.setAttribute('type', type);
    target.appendChild(jsPreload);

    js = document.createElement('link');
    js.setAttribute('href', src);
    js.setAttribute('rel', 'stylesheet');
  } else {
    js = document.createElement(tag);
    js.setAttribute('src', src);
    js.setAttribute('charset', 'UTF-8');
  }
  js.async = async;
  js.setAttribute('id', id);
  if (type !== '') js.setAttribute('type', type);
  target.appendChild(js);
};

const is_Preview = () => {
  const stage = process.env.REACT_APP_STAGE || '';
  return stage.includes('preview');
};

const defaultReducerSchema = (
  types,
  initialState = {
    isLoading: false,
    data: [],
    error: false,
  },
) => {
  return createReducer(initialState, {
    [types.ACTION_RESET]: () => ({
      ...initialState,
    }),
    [types.ACTION_ERROR]: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
      error: true,
    }),
    [types.ACTION_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
      error: false,
    }),
    [types.ACTION_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
      ...(action.payload && { params: action.payload }),
      error: false,
    }),
  });
};

const sanitizedHTML = (html) => {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: config.allowedTags,
    ALLOWED_ATTR: config.allowedAttributes,
  });
};

const parseSanitizedHTML = (html, length) => {
  if (length) {
    return parse(sanitizedHTML(truncate(html, length)));
  }
  return parse(sanitizedHTML(html));
};

const currentDate = () => {
  const previewDate = new URLSearchParams(window.location.search).get('previewDate') || null;
  return previewDate ? moment(previewDate).toDate() : moment().toDate();
};

// Parsing Date from CMS, most likely using UTC Datetime such as 2018-01-01 12:00:00
const momentUTCDate = (dateString) => {
  return moment.tz(dateString, 'UTC').toDate();
};

const isDateBetweenDateRange = (minDateString, maxDateString, currentDateString) => {
  const compareDate = moment(currentDateString).toDate();
  const minDate = momentUTCDate(minDateString);
  const maxDate = momentUTCDate(maxDateString);
  return compareDate >= minDate && compareDate <= maxDate;
};

const obj2Ary = (obj) => {
  return Array.isArray(obj) === false ? [obj] : obj;
};

const forceToArray = (data) => {
  if (isArray(data)) {
    return data;
  }
  if (isObject(data) && !Object.values(data).every((x) => x === null || x === '')) {
    return [data];
  }
  return null;
};

const localeList = (languages) => {
  return languages
    .split(',')
    .map((v) => v.trim())
    .join('|');
};

const fireTealiumEventAction = (params) => {
  if (!isEmpty(params)) {
    if (window.utag) {
      window.utag.link(params);
    } else {
      let tryCount = 0;
      const waitTealiumLibraryInit = setInterval(() => {
        console.log('waitTealiumLibraryInit.....');
        tryCount += 1;
        if (window.utag) {
          window.utag.link(params);
          tryCount = 0;
          clearInterval(waitTealiumLibraryInit);
        }
        if (tryCount > config.interval.times) {
          console.log('TealiumLibrary init Fail within 50 try');
          tryCount = 0;
          clearInterval(waitTealiumLibraryInit);
        }
      }, config.interval.frequency);
    }
  }
};

const makeTealiumVideoEventAction = (
  videoId,
  videoType,
  eventAction,
  eventName,
  eventTitle,
  videoName,
  videoPosition,
  videoDuration,
  vSeriesName,
) => {
  return {
    event_action: eventAction,
    event_name: eventName,
    event_title: `Academy:${eventTitle}`,
    event_category: 'Video',
    event_click: 1,
    site_url: '/virtual/academy/video.htm',
    link_name: `${eventTitle} - ${videoName}`,
    link_category: eventTitle,
    video_name: videoName,
    video_position: videoPosition,
    video_duration: videoDuration,
    video_series: vSeriesName,
    video_id: videoId,
    video_type: videoType,
  };
};

const fireVideoEventAction = (
  videoId,
  videoType,
  eventAction,
  eventName,
  eventTitle,
  videoName,
  videoPosition,
  videoDuration,
  vSeriesName,
  currentMilestone = 0,
) => {
  const params = makeTealiumVideoEventAction(
    videoId,
    videoType,
    eventAction,
    eventName,
    eventTitle,
    videoName,
    videoPosition,
    videoDuration,
    vSeriesName,
  );

  if (currentMilestone !== 0) {
    Object.assign(params, {
      video_milestone: currentMilestone,
    });
  }

  fireTealiumEventAction(params);

  console.log('fireTealiumEventAction params', params);

  if (window._tdctag) {
    window._tdctag.dcsMultiTrack(
      'DCS.dcsuri',
      '/virtual/academy/video.htm',
      'WT.ti',
      `Academy : ${eventTitle} - ${videoName}`,
      'WT.prdclick',
      '1',
      'WT.y',
      'y',
      'DCSext.cg_hktdc_category',
      '',
      'DCSext.cg_section',
      '',
      'DCSext.cg_subsection',
      '',
      'DCSext.cg_language',
      '',
    );
  }
};

const fireOtherEventAction = (tParams = {}, videoData = {}) => {
  const tParamsObj = tParams;

  Object.assign(tParamsObj, {
    link_name: sprintf(tParamsObj.link_name, videoData.video_name || ''),
  });

  Object.assign(tParamsObj, videoData);

  console.log('fireOtherEventAction', tParamsObj);

  fireTealiumEventAction(tParamsObj);
};

const fireTealiumEvent = (tealiumData) => {
  const fireTealiumAction = () => {
    window.utag.link(JSON.parse(tealiumData));
  };

  const fireTealium = () => {
    if (window.utag) {
      fireTealiumAction();
    } else {
      console.log('====================================');
      console.log('window utag not ready');
      console.log('====================================');

      let tryCount = 0;
      const waitTealiumLibraryInit = setInterval(() => {
        console.log('waitTealiumLibraryInit.....');
        tryCount++;
        if (window.utag) {
          fireTealiumAction();
          tryCount = 0;
          clearInterval(waitTealiumLibraryInit);
        }
        if (tryCount > config.interval.times) {
          console.log(`TealiumLibrary init Fail within ${config.interval.times} try`);
          tryCount = 0;
          clearInterval(waitTealiumLibraryInit);
        }
      }, config.interval.frequency);
    }
  };

  fireTealium();
};

const mergeHighLightList = (videoList, queryLatest, queryHighlight, noOfTotalVideo) => {
  const { series = {} } = videoList || {};
  const listsLatest = ((series[queryLatest] || {}).data || {}).lists || [];
  const listsHighlight = ((series[queryHighlight] || {}).data || {}).lists || [];
  const lists = [...listsLatest, ...listsHighlight];
  const listsUnique = lists.filter(
    (v, i, a) => a.findIndex((t) => t.fileName === v.fileName) === i,
  );
  const limitLists = listsUnique.slice(0, noOfTotalVideo);

  return limitLists;
};

export {
  isExternalURL,
  constructPath,
  newEle,
  is_Preview,
  defaultReducerSchema,
  sanitizedHTML,
  parseSanitizedHTML,
  currentDate,
  momentUTCDate,
  isDateBetweenDateRange,
  obj2Ary,
  forceToArray,
  localeList,
  fireVideoEventAction,
  fireTealiumEvent,
  fireOtherEventAction,
  isSSOLoggedIn,
  mergeHighLightList,
};
