export default {
  lscsPath: 'http://cmsappuat01.hktdc.org/lscs/v1/document$',
  projectNamePath: '/default/main/',
  apiContext: '/default/main/%%PROJECTNAME%%/WORKAREA/default',
  subDirectoryPath: '/preview',
  routePathTo: `/preview/${process.env.REACT_APP_TSPROJECTNAME}`,
  locationServiceEndPoint: 'https://api-general-uat.hktdc.com/location/v1/location-service',
  footer_js_env: '-uat',
  autoCompletePath: 'https://api-search-uat.hktdc.com/autocomplete/v1/autocomplete-search',
  eventPath: 'https://event-uat.hktdc.com',
  researchDomain: 'https://research-uat.hktdc.com',
  sbeProgramePath: 'https://api-cms-uat.hktdc.com/sbe/v1/seminar/programme',
  sbeSpeakerPath: 'https://api-cms-uat.hktdc.com/sbe/v1/seminar/speakers',
  searchCat: 'dac',
  academySearchPath: 'https://api-academy-uat.hktdc.com/digital-academy/v1/academies',
};
