import BuildSIT from './BuildSIT';
import BuildUAT from './BuildUAT';
import BuildSITPreview from './BuildSITPreview';
import BuildUATPreview from './BuildUATPreview';
import BuildPRD from './BuildPRD';
import BuildPRDPreview from './BuildPRDPreview';
import BuildArchivePRD2020 from './BuildArchivePRD2020';
import BuildArchiveSIT2019 from './BuildArchiveSIT2019';
import BuildArchiveSIT2020 from './BuildArchiveSIT2020';
import BuildArchiveUAT2020 from './BuildArchiveUAT2020';
import BuildArchiveSIT2021 from './BuildArchiveSIT2021';
import BuildArchiveUAT2021 from './BuildArchiveUAT2021';
import BuildArchivePRD2021 from './BuildArchivePRD2021';
import Localhost from './Localhost';

let otherConfig = '';

switch (process.env.REACT_APP_STAGE) {
  case 'buildSIT':
    otherConfig = BuildSIT;
    break;
  case 'buildUAT':
    otherConfig = BuildUAT;
    break;
  case 'buildSITPreview':
    otherConfig = BuildSITPreview;
    break;
  case 'buildUATPreview':
    otherConfig = BuildUATPreview;
    break;
  case 'buildPRD':
    otherConfig = BuildPRD;
    break;
  case 'buildPRDPreview':
    otherConfig = BuildPRDPreview;
    break;
  case 'BuildArchivePRD2020':
    otherConfig = BuildArchivePRD2020;
    break;
  case 'BuildArchiveSIT2019':
    otherConfig = BuildArchiveSIT2019;
    break;
  case 'BuildArchiveSIT2020':
    otherConfig = BuildArchiveSIT2020;
    break;
  case 'BuildArchiveUAT2020':
    otherConfig = BuildArchiveUAT2020;
    break;
  case 'BuildArchiveSIT2021':
    otherConfig = BuildArchiveSIT2021;
    break;
  case 'BuildArchiveUAT2021':
    otherConfig = BuildArchiveUAT2021;
    break;
  case 'BuildArchivePRD2021':
    otherConfig = BuildArchivePRD2021;
    break;
  default:
    otherConfig = Localhost;
    break;
}

export default {
  debounceFetchTimeMS: 250,
  cmsProjectName: process.env.REACT_APP_TSPROJECTNAME,
  routePublic_URL: process.env.PUBLIC_URL || '',
  allowedTags: [
    'a',
    'abbr',
    'acronym',
    'address',
    // 'applet',
    'area',
    'article',
    'aside',
    'audio',
    'b',
    // 'base',
    // 'basefont',
    'bdi',
    'bdo',
    'big',
    'blockquote',
    'body',
    'br',
    'button',
    'canvas',
    'caption',
    'center',
    'cite',
    'code',
    'col',
    'colgroup',
    'data',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'dir',
    'div',
    'dl',
    'dt',
    'em',
    // 'embed',
    'fieldset',
    'figcaption',
    'figure',
    'font',
    'footer',
    'form',
    // 'frame',
    // 'frameset',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    // 'head',
    'header',
    'hr',
    // 'html',
    'i',
    'iframe',
    'img',
    'input',
    'ins',
    'kbd',
    'label',
    'legend',
    'li',
    'link',
    'main',
    'map',
    'mark',
    // 'meta',
    'meter',
    'nav',
    // 'noframes',
    // 'noscript',
    // 'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'p',
    // 'param',
    'picture',
    'pre',
    'progress',
    'q',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'script',
    'section',
    'select',
    'small',
    'source',
    'span',
    'strike',
    'strong',
    'style',
    'sub',
    'summary',
    'sup',
    'svg',
    'table',
    'tbody',
    'td',
    'template',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'title',
    'tr',
    'track',
    'tt',
    'u',
    'ul',
    'var',
    'video',
    'wbr',
  ],
  allowedAttributes: [
    'accept',
    'accesskey',
    'action',
    'align',
    'allow',
    'allowfullscreen',
    'alt',
    'autocomplete',
    'autofocus',
    'autoplay',
    'bgcolor',
    'border',
    'checked',
    'cite',
    'class',
    'color',
    'cols',
    'colspan',
    'content',
    'contenteditable',
    'controls',
    'coords',
    'data',
    'data-*',
    'datetime',
    'default',
    'defer',
    'dir',
    'dirname',
    'disabled',
    'download',
    'draggable',
    'dropzone',
    'enctype',
    'for',
    'form',
    'formaction',
    'headers',
    'height',
    'hidden',
    'high',
    'frameborder',
    'href',
    'hreflang',
    'id',
    'ismap',
    'kind',
    'label',
    'lang',
    'list',
    'loop',
    'low',
    'max',
    'maxlength',
    'media',
    'method',
    'min',
    'multiple',
    'muted',
    'name',
    'novalidate',
    'onabort',
    'onafterprint',
    'onbeforeprint',
    'onbeforeunload',
    'onblur',
    'oncanplay',
    'oncanplaythrough',
    'onchange',
    'onclick',
    'oncontextmenu',
    'oncopy',
    'oncuechange',
    'oncut',
    'ondblclick',
    'ondrag',
    'ondragend',
    'ondragenter',
    'ondragleave',
    'ondragover',
    'ondragstart',
    'ondrop',
    'ondurationchange',
    'onemptied',
    'onended',
    'onerror',
    'onfocus',
    'onhashchange',
    'oninput',
    'oninvalid',
    'onkeydown',
    'onkeypress',
    'onkeyup',
    'onload',
    'onloadeddata',
    'onloadedmetadata',
    'onloadstart',
    'onmousedown',
    'onmousemove',
    'onmouseout',
    'onmouseover',
    'onmouseup',
    'onmousewheel',
    'onoffline',
    'ononline',
    'onpagehide',
    'onpageshow',
    'onpaste',
    'onpause',
    'onplay',
    'onplaying',
    'onpopstate',
    'onprogress',
    'onratechange',
    'onreset',
    'onresize',
    'onscroll',
    'onsearch',
    'onseeked',
    'onseeking',
    'onselect',
    'onstalled',
    'onstorage',
    'onsubmit',
    'onsuspend',
    'ontimeupdate',
    'ontoggle',
    'onunload',
    'onvolumechange',
    'onwaiting',
    'onwheel',
    'open',
    'optimum',
    'pattern',
    'placeholder',
    'poster',
    'preload',
    'readonly',
    'rel',
    'required',
    'reversed',
    'rows',
    'rowspan',
    'sandbox',
    'scope',
    'selected',
    'shape',
    'size',
    'sizes',
    'span',
    'spellcheck',
    'src',
    'srcdoc',
    'srclang',
    'srcset',
    'start',
    'step',
    'style',
    'tabindex',
    'target',
    'title',
    'translate',
    'type',
    'usemap',
    'value',
    'width',
    'wrap',
  ],
  tealium: {
    subsection: 'others',
    pageType: 'section',
  },
  tealiumHome: {
    pageType: 'home',
  },
  tealiumPressRelease: {
    subsection: 'Press Release',
    pageType: 'article',
  },
  tealiumSearchResult: {
    subsection: 'Search Result',
    pageType: 'search',
  },
  tealiumPromotion: {
    subsection: 'Promotion',
    pageType: 'article',
  },
  scriptUrl: {
    footer: `https://portal${otherConfig.footer_js_env}.hktdc.com/resources/footer/standard-%%%lang%%%-latest.js`,
    cookieConsentCSS: process.env.REACT_APP_CDN_COOKIECONSENT_CSS_MIN,
    cookieContentHKTDCCSS: process.env.REACT_APP_CDN_COOKIECONSENT_CSS_HKTDC,
    cookieConsentJS: process.env.REACT_APP_CDN_COOKIECONSENT_JS_MIN,
    cookieConsentHKTDCJS: process.env.REACT_APP_CDN_COOKIECONSENT_JS_HKTDC,
    webTrendScript: '/js/dcs_tag.jvxfy4ru.js',
    webTrendScriptCustom: '/js/webtrends_custom_tag.jvxfznpl.js',
  },
  itemsPerPage: 2,
  pageType: {
    siteconfig: 'Settings/Site Config',
    homePage: 'Pages/Main Page',
    indexPage: 'Pages/Index Page',
    linksPage: 'Pages/Links Page',
    photoGalleryPage: 'Pages/Photo Gallery Page',
    pressReleasePage: 'Pages/Press Release Page',
    promotionPage: 'Pages/Promotion Page',
    programmePage: 'Pages/Programme Page',
    speakersPage: 'Pages/Speakers Page',
    staticPage: 'Pages/Static Page',
    videoGalleryPage: 'Pages/Video Gallery Page',
    videoSeries: 'Settings/Video Series',
    academyConfig: 'Settings/Academy Config',
  },
  pageTypeArchivePathMap: {
    'Pages/Main Page': 'main/',
    'Pages/Index Page': 'index/',
    'Pages/Press Release Page': 'press/',
    'Pages/Promotion Page': 'promotion/',
    'Pages/Links Page': 'link/',
    'Pages/Programme Page': 'programme/',
    'Pages/Speakers Page': 'speaker/',
    'Pages/Video Gallery Page': 'video/',
    'Pages/Photo Gallery Page': 'photo/',
    'Pages/Static Page': 'static/',
    'Settings/Announcement': 'announcement/',
    'Settings/Site Config': 'siteConfig/',
  },
  sso_lang_map: { en: 'en', tc: 'zh-tw', sc: 'zh-cn' },
  momentLangMap: { en: 'en', tc: 'zh-hk', sc: 'zh-cn' },
  sliderTruncateLength: { en: 90, tc: 50, sc: 50 },
  interval: {
    frequency: 250,
    times: 50,
  },
  customEvent: {
    ssoReady: 'HKTDCSsoReady',
    ssoLoaded: 'HKTDCSsoLoaded',
    cookieConsentDone: 'HKTDCCookieConsentDone',
    langChange: 'HKTDCLangChange',
  },
  indexPageDefaultThumbnail:
    'https://img.hktdc.com/eyJidWNrZXQiOiJjbXMtaW1nbGliLXByZCIsImtleSI6Im15aGt0ZGMvMjAyMDA2MjMvdW12M2x6bHMtU0V0VVJFTXRiRzluYnc9PS5qcGVnIiwicmVnaW9uIjoiYXAtc291dGhlYXN0LTEiLCJmb3JtYXQiOiJqcGVnIiwicXVhbGl0eSI6MTAwLCJlZGl0cyI6e319',

  paginatioinLimitPerPage: 10, // must be even number
  academyParams: {
    RefSource: 'ref_source',
    RefTerm: 'ref_term',
    SearchKeyword: 'SearchKeyword',
  },
  ...otherConfig,
};
